import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Layout from '../components/Layout';

const PrivacyPolicy = () => {

    const [content, setContent] = useState("");

    useEffect(() => {
        async function loadContent() {
            try {
                let res = await fetch("/privacy-policy.md");
                res = await res.text();
                setContent(res);
            } catch (e) {
                console.log(e);
            }
        }
        loadContent();
    }, []);

    return (
        <Layout>
            <div className="inner-content">
                <div className="policy-content">
                    <ReactMarkdown source={content} />
                </div>
            </div>
        </Layout>
    )
}

export default PrivacyPolicy;